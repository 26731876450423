<template>
  <div>
    <!-- 操作選單 -->
    <div class="mb-4">
      <button
        class="tw-btn tw-btn-secondary"
        @click="showModal('advancedSearch')"
      >
        進階搜尋
      </button>
    </div>
    <!-- 訂單列表 -->
    <div class="mb-3">
      <ul v-if="merchOrders.length > 0">
        <li
          class="merchorder-list mb-2"
          :class="{
            'border-success':
              data.arrivalStatus &&
              data.checkOutStatus &&
              data.paymentStatus &&
              data.shippedStatus,
          }"
          v-for="data in merchOrders"
          :key="data.id"
        >
          <!-- 訂單資訊 -->
          <div class="row mb-2">
            <div class="col-2 p-0">
              <Image
                class="img"
                :imageHash="data.imageHash"
                size="s"
                v-if="data.imageHash"
                :alt="data.imageHash"
              ></Image>
              <img
                class="img"
                src="@/assets/other-images/noImg.png"
                alt=""
                v-else
              />
            </div>
            <div class="col-7 text-break">
              <p class="mb-2 fw-bolder text-primary">訂單編號: {{ data.id }}</p>
              <p class="mb-2 fw-bolder">
                {{ data.merchandise.name }}
              </p>
              <p class="mb-2 text-secondary">
                <i class="bi bi-cart-check me-1"></i>{{ data.merchandiseStyle }}
              </p>
              <p class="mb-2 text-secondary">
                <i class="bi bi-chat-dots me-1"></i>
                <span v-if="data.note">{{ data.note }}</span>
                <span v-else>沒有備註</span>
              </p>
              <p>
                <i class="bi bi-calendar2-check me-1"></i>{{ data.createTime }}
              </p>
            </div>
            <div class="col-3">
              <p class="mb-2">{{ data.quantity }} X {{ data.price }}</p>
              <p class="mb-3 fw-bolder text-danger total-money">
                總金額:
                {{ $methods.numberToFixed(data.quantity * data.price) }}元
              </p>
            </div>
          </div>
          <!-- 訂單狀態 -->
          <div class="row border-top border-2 pt-2">
            <span class="col">
              <p class="badge bg-success d-block" v-if="data.arrivalStatus">
                已到貨
              </p>
              <p class="badge bg-danger d-block" v-else>
                未到貨({{ data.quantity - data.allocatedQuantity }})
              </p>
            </span>
            <span class="col">
              <p class="badge bg-success d-block" v-if="data.checkOutStatus">
                已結單
              </p>
              <p class="badge bg-danger d-block" v-else>
                未結單({{ data.quantity - data.checkOutQuantity }})
              </p>
            </span>
            <span class="col">
              <p class="badge bg-success d-block" v-if="data.paymentStatus">
                已付款
              </p>
              <p class="badge bg-danger d-block" v-else>
                未付款({{ data.quantity - data.paymentQuantity }})
              </p>
            </span>
            <span class="col">
              <p class="badge bg-success d-block" v-if="data.shippedStatus">
                已寄出
              </p>
              <p class="badge bg-danger d-block" v-else>
                未寄出({{ data.quantity - data.shippingQuantity }})
              </p>
            </span>
          </div>
        </li>
      </ul>
      <p class="alert-danger fw-bolder p-3 text-center" v-else>無任何訂單</p>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vuex
import { mapState } from "vuex";
// api
import { lineLoginWithoutReg } from '@/methods/API/lineLoginWithoutReg'
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'

export default {
  data() {
    return {
      // modal
      advancedSearchModal: {},
      // data
      storeId: 0,
      serverToken: '',
      groupLinkId: '',
      lineUserId: '',
      participantId: 0,
      slaveId: 0,
      merchOrders: [],
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        singleSelect: {
          orderStatus: '未完成',
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  created() {
    this.initialization()
    this.computedD4TimeRange()
    if (this.$route.meta.currentModal === 'line') this.initLineSdk()
    else if (this.$route.meta.currentModal === 'fb') this.advancedSearch() // FB 免註冊頁面已停用
  },
  mounted() {
    this.createModals(['advancedSearchModal'])
  },
  computed: {
    ...mapState('StoreList', {
      ownerStores: state => state.ownerStores,
      customerStores: state => state.customerStores,
      fbStores: state => state.fbStores
    })
  },
  methods: {
    initialization() {
      if (this.$route.meta.currentModal === 'line') {
        this.storeId = this.$route.query.storeId
        this.groupLinkId = this.$route.query.groupLinkId
      } else if (this.$route.meta.currentModal === 'fb') {
        this.serverToken = this.$methods.getCookie('fb_serverToken')
        this.storeId = this.$route.params.storeId
        this.fbStores.data.forEach(item => {
          if (item.storeId == this.storeId) this.participantId = item.id
        })
      }
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'orderStatus',
          key: '訂單狀態',
          value: [
            {
              id: 'A1',
              value: '未完成'
            },
            {
              id: 'A2',
              value: '已完成'
            },
            {
              id: 'A3',
              value: '全部'
            },
          ]
        },
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // * line 內嵌 (start)
    async initLineSdk() {
      this.$methods.switchLoading('show')
      try {
        await liff.init({ liffId: process.env.VUE_APP_MERCHORDER_PAGE_LINE_LIFFID })
        const context = liff.getContext()
        if (context.type !== 'external') this.lineUserId = context.userId;
        console.log(this.lineUserId)
        this.lineLogin()
      } catch (error) {
        console.log(error)
        this.$methods.switchLoading('hide')
        alert('尚未取得 Line 相關資訊')
      }
    },
    lineLogin() {
      const result = lineLoginWithoutReg(this.groupLinkId, this.lineUserId)
      result.then(res => {
        if (res.code === '200') {
          console.log(res)
          this.serverToken = res.data
          this.getParticipantId()
        }
      }).catch(err => {
        console.log(err)
        this.$methods.switchLoading('hide')
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
      })
    },
    // * line 內嵌 (end)
    // 取得 participantId 與 slaveId
    getParticipantId() {
      this.$methods.switchLoading('show')
      const result = parseTokenToGetTheParticipant(this.storeId, this.serverToken)
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          this.participantId = res.data.id
          this.slaveId = res.data.slaves.length > 0 ? res.data.slaves[0].id : 0
          this.advancedSearch()
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 計算預設時間區間
    computedD4TimeRange() {
      console.log(this.$methods.moment().format('YYYY-MM-DD'))
      console.log(this.$methods.moment().subtract(90, 'days').format('YYYY-MM-DD'))
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(90, 'days').format('YYYY-MM-DD')
    },
    showModal(status) {
      if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      this.$methods.switchLoading('show')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      let participantIds = []
      if (this.participantId) participantIds.push(this.participantId)
      if (this.slaveId) participantIds.push(this.slaveId)
      let query = ''
      if (this.$route.meta.currentModal === 'line') {
        // 如果有 participant
        if (participantIds.length > 0) query = {"columns":[{"columnName":"id","siteObjectType":26}],"distinct":true,"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":26},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"buyerId","siteObjectType":26},"operator":{"operator":"=","type":3,"value":participantIds}}],"operator":1},"siteObjectType":26,"sqlCommandType":1}
        // 若沒有就代表這個 parseToken 失效 (通常 parseToken 就算是虛擬顧客也可以有資料，但下面這串 query 先留著，以防萬一)
        else query = {"columns":[{"columnName":"Id","siteObjectType":26},{"columnName":"CreateTime","siteObjectType":26}],"group":{"groups":[{"column":{"columnName":"BuyerId","siteObjectType":26},"operator":{"operator":"=","type":1,"value":{"columns":[{"columnName":"Id","siteObjectType":13}],"group":{"groups":[{"column":{"columnName":"StoreId","siteObjectType":13},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"UserType","siteObjectType":13},"operator":{"type":3,"value":{"columns":[{"columnName":"UserType","siteObjectType":40}],"distinct":true,"group":{"column":{"columnName":"AppUserid","siteObjectType":40},"operator":{"operator":"=","type":1,"value":this.lineUserId}},"siteObjectType":40,"sqlCommandType":1}}},{"column":{"columnName":"UserId","siteObjectType":13},"operator":{"type":3,"value":{"columns":[{"columnName":"UserId","siteObjectType":40}],"distinct":true,"group":{"column":{"columnName":"AppUserid","siteObjectType":40},"operator":{"operator":"=","type":1,"value":this.lineUserId}},"siteObjectType":40,"sqlCommandType":1}}}],"operator":1},"siteObjectType":13,"sqlCommandType":1}}}],"operator":1},"orders":[{"asc":false,"column":{"columnName":"CreateTime","siteObjectType":26}}],"siteObjectType":26,"sqlCommandType":1}
      } else if (this.$route.meta.currentModal === 'fb') query = {"columns":[{"columnName":"id","siteObjectType":26},{"columnName":"CreateTime","siteObjectType":26}],"distinct":true,"group":{"groups":[{"column":{"columnName":"buyerId","siteObjectType":26},"operator":{"operator":"=","type":1,"value":this.participantId}}],"operator":1},"orders":[{"asc":false,"column":{"columnName":"CreateTime","siteObjectType":26}}],"siteObjectType":26,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 26
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 26
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      if (closeModal) this.advancedSearchModal.hide()
      this.getMerchOrders(query)
    },
    // 找訂單
    getMerchOrders(query){
      const vm = this
      const getMerchOrdersApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getCheckOutQuantity,getPaymentQuantity,getShippingQuantity,getMerchandiseStyles,getMerchandise{getMerchandisePictureLinks}}'
        }
      ]
      this.merchOrders = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchOrdersApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchOrders = res.data[0].objects
            vm.sortOutMerchOrders(merchOrders)
          } else {
            vm.SweetAlert(res.code, res.message)
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理訂單
    sortOutMerchOrders(merchOrders) {
      merchOrders.forEach(item => {
        // 原資料
        const object = {
          allocatedQuantity: item.allocatedQuantity,
          arrivalStatus: null,
          buyerId: item.buyerId,
          checkOutQuantity: item.checkOutQuantity,
          checkOutStatus: null,
          class: item.class,
          createTime: this.$methods.moment(item.createTime).format("YYYY-MM-DD HH:mm:ss"),
          createTimeTimeStamp: item.createTime,
          creatorId: item.creatorId,
          id: item.id,
          imageHash: '',
          merchandise: item.merchandise,
          merchandiseId: item.merchandiseId,
          merchandiseStyle: '',
          merchandiseStyles: item.merchandiseStyles,
          note: item.note,
          paymentQuantity: item.paymentQuantity,
          paymentStatus: null,
          price: item.price,
          quantity: item.quantity,
          shippedStatus: null,
          shippingQuantity: item.shippingQuantity,
          soldOutQuantity: item.soldOutQuantity,
          source: item.creationType,
          sourceMessage: item.sourceMessage,
          stockId: item.stockId,
          storeId: item.storeId,
        }
        // 需特別處理
        // 到貨狀態
        object.arrivalStatus = object.quantity === object.allocatedQuantity
        // 結單狀態
        object.checkOutStatus = object.quantity === object.checkOutQuantity
        // 付款狀態
        object.paymentStatus = object.quantity === object.paymentQuantity
        // 出貨狀態
        object.shippedStatus = object.quantity === object.shippingQuantity
        // 商品圖片
        object.merchandise.merchandisePictureLinks.some(picture => {
          if (picture.front) return object.imageHash = picture.imageHash
        })
        // 商品款式
        let merchandiseStyle = []
        object.merchandiseStyles.forEach(style => {
          merchandiseStyle.push(style.name)
        })
        object.merchandiseStyle = merchandiseStyle.join()

        // 進階搜尋 > 訂單狀態
        let matchOrderStatus = false
        switch (this.advancedSearchData.singleSelect.orderStatus) {
          case '未完成':
            if (!object.arrivalStatus || !object.checkOutStatus || !object.paymentStatus || !object.shippedStatus) matchOrderStatus = true
            break;
          case '已完成':
            if (object.arrivalStatus && object.checkOutStatus && object.paymentStatus && object.shippedStatus) matchOrderStatus = true
            break;
          case '全部':
            matchOrderStatus = true
            break;
        }

        if (object.quantity > 0 && matchOrderStatus) this.merchOrders.push(JSON.parse(JSON.stringify(object)))
      })
      this.merchOrders.sort((a, b) => {
        const aTime = a.createTimeTimeStamp
        const bTime = b.createTimeTimeStamp
        return bTime - aTime
      })
      this.$methods.switchLoading('hide')
    },
  },
}
</script>